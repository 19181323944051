import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faCircleXmark as fadCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { faFileInvoice as falFileInvoice, faMessageQuestion as falMessageQuestion, faPipe as falPipe } from '@fortawesome/pro-light-svg-icons';
import {
	faBadgeDollar as farBadgeDollar,
	faBallotCheck as farBallotCheck,
	faBell as farBell,
	faCalendarAlt as farCalendarAlt,
	faCalendarCheck as farCalendarCheck,
	faCalendarLinesPen as farCalendarLinesPen,
	faChampagneGlasses as farChampagneGlasses,
	faChartLineDown as farChartLineDown,
	faChartPieSimpleCircleDollar as farChartPieSimpleCircleDollar,
	faCheckSquare as farCheckSquare,
	faCircleCheck as farCircleCheck,
	faCircleExclamation as farCircleExclamation,
	faCirclePlay as farCirclePlay,
	faCircleStop as farCircleStop,
	faCity as farCity,
	faClock as farClock,
	faComments as farComments,
	faCopy as farCopy,
	faEnvelopeCircleCheck as farEnvelopeCircleCheck,
	faFileAlt as farFileAlt,
	faFileCertificate as farFileCertificate,
	faFileCircleQuestion as farFileCircleQuestion,
	faFilePdf as farFilePdf,
	faFileSignature as farFileSignature,
	faFolderOpen as farFolderOpen,
	faFrown as farFrown,
	faGrinStars as farGrinStars,
	faLightbulb as farLightbulb,
	faList as farList,
	faListAlt as farListAlt,
	faMeh as farMeh,
	faNotes as farNotes,
	faNoteSticky as farNoteSticky,
	faPlusSquare as farPlusSquare,
	faRefrigerator as farRefrigerator,
	faSensorTriangleExclamation as farSensorTriangleExclamation,
	faSmile as farSmile,
	faSquare as farSquare,
	faSquarePlus as farSquarePlus,
	faSquareXmark as farSquareXmark,
	faTimesCircle as farTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {
	faAngleRight,
	faAnglesDown,
	faAnglesUp,
	faArchive,
	faArrowDownToLine,
	faArrowLeftFromLine,
	faArrowRightArrowLeft,
	faArrowRightLong,
	faArrowsAlt,
	faArrowsSpin,
	faArrowsUpDownLeftRight,
	faArrowUpRightFromSquare,
	faArrowUpToLine,
	faBars,
	faBell,
	faBook,
	faBookmark,
	faBoxOpen,
	faBullhorn,
	faCalendar,
	faCalendarAlt,
	faCalendarDays,
	faCalendarLinesPen,
	faCaretDown,
	faCaretLeft,
	faCaretRight,
	faCaretSquareRight,
	faCaretUp,
	faChartBar,
	faChartKanban,
	faChartLine,
	faChartPie,
	faChartPieSimpleCircleDollar,
	faChartTreeMap,
	faCheck,
	faCheckCircle,
	faCheckDouble,
	faChevronCircleDown,
	faChevronCircleUp,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faCircleChevronLeft,
	faCircleExclamation,
	faCircleInfo,
	faCircleMinus,
	faCircleXmark,
	faClipboardList,
	faClock,
	faClockRotateLeft,
	faClone,
	faCloudDownloadAlt,
	faCloudSun,
	faCog,
	faCogs,
	faComment,
	faCommentDollar,
	faComments,
	faCommentsDollar,
	faDesktop,
	faDisplay,
	faDollarSign,
	faDownload,
	faEdit,
	faEllipsisH,
	faEllipsisVertical,
	faEmptySet,
	faEnvelope,
	faEnvelopesBulk,
	faEnvelopeSquare,
	faExchangeAlt,
	faExclamationCircle,
	faExclamationTriangle,
	faExternalLinkAlt,
	faExternalLinkSquareAlt,
	faEye,
	faEyeSlash,
	faFile,
	faFileAlt,
	faFileCheck,
	faFileCircleXmark,
	faFileContract,
	faFileDownload,
	faFileExcel,
	faFileExclamation,
	faFileExport,
	faFileInvoice,
	faFileInvoiceDollar,
	faFileSpreadsheet,
	faFileUpload,
	faFilm,
	faFlag,
	faFloppyDiskCircleArrowRight,
	faFolderOpen,
	faGavel,
	faGem,
	faGift,
	faHandHoldingUsd,
	faHeadset,
	faHome,
	faInfo,
	faInfoCircle,
	faLaptopMedical,
	faLayerGroup,
	faList,
	faListUl,
	faLock,
	faMagnifyingGlass,
	faMailBulk,
	faMapLocationDot,
	faMedal,
	faMedkit,
	faMessageDollar,
	faMobileNotch,
	faMobileScreen,
	faMoneyBillWave,
	faNewspaper,
	faNotes,
	faNoteSticky,
	faPaperPlane,
	faPartyHorn,
	faPencilAlt,
	faPenSquare,
	faPenToSquare,
	faPercent,
	faPercentage,
	faPhone,
	faPhoneAlt,
	faPhoneOffice,
	faPhoneSlash,
	faPhoneVolume,
	faPhotoVideo,
	faPiggyBank,
	faPlay,
	faPlug,
	faPlugCircleBolt,
	faPlugCircleXmark,
	faPlus,
	faPlusCircle,
	faQuestion,
	faQuestionCircle,
	faRedoAlt,
	faReply,
	faRocket,
	faRotateRight,
	faSave,
	faSearch,
	faSearchDollar,
	faShareNodes,
	faShareSquare,
	faSignOutAlt,
	faSlash,
	faSliders,
	faSpinner,
	faSquare,
	faSquareCheck,
	faSquareInfo,
	faSquarePlus,
	faStar,
	faSunCloud,
	faTable,
	faTachometerAlt,
	faTasks,
	faThList,
	faThumbsDown,
	faTimes,
	faTimesCircle,
	faToggleOff,
	faToggleOn,
	faTrash,
	faTrashAlt,
	faTrashUndo,
	faTriangleExclamation,
	faUndo,
	faUndoAlt,
	faUpload,
	faUser,
	faUserCheck,
	faUserGroup,
	faUsers,
	faUserSecret,
	faUserSlash,
	faUserTie,
	faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { EditorModule } from '@tinymce/tinymce-angular';

import { NgJsonEditorModule } from 'ang-jsoneditor';
import { TextMaskModule } from 'angular2-text-mask';
import { DxColorBoxModule, DxDataGridModule, DxDateBoxModule, DxFileUploaderModule, DxListModule, DxScrollViewModule, DxSortableModule } from 'devextreme-angular';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TagInputModule } from 'ngx-chips';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { ContractCustomizeUserGridConfigModalComponent } from '../contract/modals/contract-customize-user-grid-config/contract-customize-user-grid-config.component';
import { ContractEditUserGridConfigModalComponent } from '../contract/modals/contract-edit-user-grid-config/contract-edit-user-grid-config.component';
import { ContractNewUserGridConfigModalComponent } from '../contract/modals/contract-new-user-grid-config/contract-new-user-grid-config.component';
import { GeneralContractTableComponent } from './components/contract-tables/general-contract-table/general-contract-table.component';
import { OperationsContractActivityTableComponent } from './components/contract-tables/operations-contract-activity-table/operations-contract-activity-table.component';
import { CustomerAvailabilityViewComponent } from './components/customer/customer-availability-view/customer-availability-view.component';
import { CustomerAvailabilityComponent } from './components/customer/customer-availability/customer-availability.component';
import { ActionDropdownComponent } from './components/form/action-dropdown/action-dropdown.component';
import { AgentSelectorComponent } from './components/form/agent-selector/agent-selector.component';
import { ContractSelectorModalComponent } from './components/form/contract-selector/contract-selector-modal/contract-selector-modal.component';
import { ContractSelectorComponent } from './components/form/contract-selector/contract-selector.component';
import { FileSelectorComponent } from './components/form/file-selector/file-selector.component';
import { FilterDropdownComponent } from './components/form/filter-dropdown/filter-dropdown.component';
import { FormDropdownComponent } from './components/form/form-dropdown/form-dropdown.component';
import { FormElementComponent } from './components/form/form-element/form-element.component';
import { HtmlEditorComponent } from './components/form/html-editor/html-editor.component';
import { OptionsFilterDropdownComponent } from './components/form/options-filter-dropdown/options-filter-dropdown.component';
import { TypeaheadComponent } from './components/form/typeahead/typeahead.component';
import { UserSelectorComponent } from './components/form/user-selector/user-selector.component';
import { LookupContractComponent } from './components/lookup-contract/lookup-contract.component';
import { MarketRecommendationsComponent } from './components/market-recommendations/market-recommendations.component';
import { PagerComponent } from './components/pager/pager.component';
import { RateBarComponent } from './components/rate-bar/rate-bar.component';
import { SupplierNoteComponent } from './components/supplier-note/supplier-note.component';
import { TopBannerAlertComponent } from './components/top-banner-alert/top-banner-alert.component';
import { VjsPlayerComponent } from './components/vjs-player/vjs-player.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { DblClickDirective } from './directives/dbl-click.directive';
import { LoaderComponent } from './loader/loader.component';
import { AddressValidationModalComponent } from './modals/address-validation/address-validation.component';
import { BrowserDeniedModalComponent } from './modals/browser-denied/browser-denied.component';
import { EsiidLookupComponent } from './modals/esiid-lookup/esiid-lookup.component';
import { FeatureTourModalComponent } from './modals/feature-tour-modal/feature-tour-modal.component';
import { GenerateLoaLoeModalComponent } from './modals/generate-loa-loe-modal/generate-loa-loe-modal.component';
import { ModalComponent } from './modals/modal.component.abstract';
import { SelectImageModalComponent } from './modals/select-image-modal/select-image-modal.component';
import { TakeoverPopUpModalComponent } from './modals/takeover-pop-up-modal/takeover-pop-up-modal.component';
import { TopBannerAlertLearnMoreModalComponent } from './modals/top-banner-alert-learn-more-modal/top-banner-alert-learn-more-modal.component';
import { WelcomeModalComponent } from './modals/welcome/welcome.component';
import { WINDOW_PROVIdERS } from './models/window-provider';
import { PageWarningsComponent } from './page-warnings/page-warnings.component';
import { AgentCommissionPayableSchedulesPipe } from './pipes/agent-commission-payable-schedules.pipe';
import { AgentCommissionPayablesPipe } from './pipes/agent-commission-payables.pipe';
import { AgentCommissionsPaidReportPipe } from './pipes/agent-commissions-paid-report.pipe';
import { AgentCommissionsPipe } from './pipes/agent-commissions.pipe';
import { AgentsWithLoginsPipe } from './pipes/agents-with-logins.pipe';
import { AuditReportFriendlyDateColumnPipe } from './pipes/audit-report-friendly-date-column.pipe';
import { BillAttachmentsPipe } from './pipes/bill-attachments.pipe';
import { CompensationFilterPipe } from './pipes/compensation-filter.pipe';
import { ConvertBidUnitsPipe } from './pipes/convert-bid-units.pipe';
import { EmailPreferenceFilterPipe } from './pipes/email-preference-filter.pipe';
import { EmailPreferenceSettingTargetDateOptionsFilterPipe } from './pipes/email-preference-setting-target-date-options-filter.pipe';
import { FaqShortAnswerPipe } from './pipes/faq-short-answer.pipe';
import { FilterBestPlansPipe } from './pipes/filter-best-plans.pipe';
import { FilterContractLocationStatusReasonTypesPipe } from './pipes/filter-contract-location-status-reason-types.pipe';
import { FilterControlPipe } from './pipes/filter-control.pipe';
import { FilterEducationCategoryTopicsPipe } from './pipes/filter-education-category-topics.pipe';
import { FilterEducationPipe } from './pipes/filter-education.pipe';
import { FilterEmailCriteriasPipe } from './pipes/filter-email-criterias.pipe';
import { FilterGreenPercentagesPipe } from './pipes/filter-green-percentages.pipe';
import { FilterLoaderGroupsPipe } from './pipes/filter-loader-groups.pipe';
import { FilterLoadersPipe } from './pipes/filter-loaders.pipe';
import { FilterPlanLoadFactorSettingsPipe } from './pipes/filter-plan-load-factor-settings.pipe';
import { FilterPlansPipe } from './pipes/filter-plans.pipe';
import { FilterPricingAnalyticsEffectiveDatesPipe } from './pipes/filter-pricing-analytics-effective-dates.pipe';
import { FilterPricingAnalyticsTermsPipe } from './pipes/filter-pricing-analytics-terms.pipe';
import { FilterPricingAnalyticsUsagesPipe } from './pipes/filter-pricing-analytics-usages.pipe';
import { FilterPricingAnalyticsUtilitiesPipe } from './pipes/filter-pricing-analytics-utilities.pipe';
import { FilterProductsPipe } from './pipes/filter-products.pipe';
import { FilterReportCommissionPaidParentPipe } from './pipes/filter-report-commission-paid-parent.pipe';
import { FilterReportCommissionPaidPipe } from './pipes/filter-report-commission-paid.pipe';
import { FilterStateColumnsPipe } from './pipes/filter-state-columns.pipe';
import { FilterSuppliersPipe } from './pipes/filter-suppliers.pipe';
import { FilterTermsPipe } from './pipes/filter-terms.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FormatCommissionPayPlanDetailsPipe } from './pipes/format-commission-pay-plan-details.pipe';
import { FormatLicensePlansPipe } from './pipes/format-license-plans.pipe';
import { InternalNotesPipe } from './pipes/internal-notes.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { LicensePlanControlFilterPipe } from './pipes/license-plan-control-filter.pipe';
import { LicensePlanFilterPipe } from './pipes/license-plan-filter.pipe';
import { LicensePlanNameFilterPipe } from './pipes/license-plan-name-filter.pipe';
import { LoadFactorPipe } from './pipes/load-factor.pipe';
import { MarginDisplayPipe } from './pipes/margin-display.pipe';
import { MarketOverviewServiceTypeFilterPipe } from './pipes/market-overview-service-type-filter.pipe';
import { MarketOverviewStateFilterPipe } from './pipes/market-overview-state-filter.pipe';
import { MarketOverviewUtilityFilterPipe } from './pipes/market-overview-utility-filter.pipe';
import { MarketUpdateAuctionFilterPipe } from './pipes/market-update-auction-filter.pipe';
import { MarketUpdateAuctionServiceTypeFilterPipe } from './pipes/market-update-auction-service-type-filter.pipe';
import { MarketUpdateRateSummaryFilterPipe } from './pipes/market-update-rate-summary-filter.pipe';
import { MarketUpdateRateSummaryServiceTypeFilterPipe } from './pipes/market-update-rate-summary-service-type-filter.pipe';
import { MinusSignToParensPipe } from './pipes/minus-sign-to-parens.pipe';
import { MonthNamePipe } from './pipes/month-name.pipe';
import { NewLinesToBreaksPipe } from './pipes/new-lines-to-breaks.pipe';
import { NumberOrdinalPipe } from './pipes/number-ordinal.pipe';
import { OrderByControlPipe } from './pipes/order-by-control.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { PriorityPipe } from './pipes/priority.pipe';
import { ProductBidsPipe } from './pipes/product-bids.pipe';
import { RateClassFilterPipe } from './pipes/rate-class-filter.pipe';
import { RfqSettingsFilterPipe } from './pipes/rfq-settings-filter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SalesTaxPipe } from './pipes/sales-tax.pipe';
import { ServiceTypeUtilitiesPipe } from './pipes/service-type-utilities.pipe';
import { SessionFilterPipe } from './pipes/session-filter.pipe';
import { ShrinkPipe } from './pipes/shrink.pipe';
import { StatesFilterPipe } from './pipes/states-filter.pipe';
import { SupplierBidsPipe } from './pipes/supplier-bids.pipe';
import { TelPipe } from './pipes/tel.pipe';
import { UtilityFilterPipe } from './pipes/utility-filter.pipe';
import { UtilityServiceTypeMapFilterPipe } from './pipes/utility-service-type-map-filter.pipe';
import { ViewAgentEngagementEventsSortPipe } from './pipes/view-agent-engagement-events-sort.pipe';
import { WarningsFilterPipe } from './pipes/warnings-filter.pipe';
import { ZoneFilterPipe } from './pipes/zone-filter.pipe';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		NgxFilesizeModule,
		DxDataGridModule,
		DxFileUploaderModule,
		DxDateBoxModule,
		DxListModule,
		DxSortableModule,
		DxScrollViewModule,
		DxColorBoxModule,
		EditorModule,
		NgxExtendedPdfViewerModule,
		NgxJsonViewerModule,
		NgJsonEditorModule,
		FontAwesomeModule,
		TextMaskModule,
		TagInputModule,
		ModalModule.forRoot(),
		CollapseModule.forRoot(),
		BsDropdownModule.forRoot(),
		BsDatepickerModule.forRoot(),
		CarouselModule.forRoot(),
		TabsModule.forRoot(),
		TooltipModule.forRoot(),
		ProgressbarModule.forRoot(),
		AccordionModule.forRoot(),
		TypeaheadModule.forRoot(),
		ButtonsModule.forRoot(),
	],
	declarations: [
		ShrinkPipe,
		FilterPipe,
		OrderByPipe,
		FormatLicensePlansPipe,
		WelcomeModalComponent,
		LoaderComponent,
		ServiceTypeUtilitiesPipe,
		OrderByControlPipe,
		FilterControlPipe,
		PageWarningsComponent,
		MonthNamePipe,
		TelPipe,
		FilterPlansPipe,
		FilterBestPlansPipe,
		SalesTaxPipe,
		LoadFactorPipe,
		ProductBidsPipe,
		SupplierBidsPipe,
		UtilityFilterPipe,
		ZoneFilterPipe,
		PriorityPipe,
		WarningsFilterPipe,
		LicensePlanFilterPipe,
		LicensePlanNameFilterPipe,
		AddressValidationModalComponent,
		InternalNotesPipe,
		PhonePipe,
		BillAttachmentsPipe,
		JoinPipe,
		MinusSignToParensPipe,
		UtilityServiceTypeMapFilterPipe,
		MarketOverviewStateFilterPipe,
		MarketOverviewServiceTypeFilterPipe,
		MarketOverviewUtilityFilterPipe,
		SafeHtmlPipe,
		AgentCommissionsPipe,
		BrowserDeniedModalComponent,
		EsiidLookupComponent,
		TakeoverPopUpModalComponent,
		FeatureTourModalComponent,
		MarginDisplayPipe,
		AgentsWithLoginsPipe,
		FilterSuppliersPipe,
		FilterTermsPipe,
		FilterLoadersPipe,
		FilterProductsPipe,
		FilterLoaderGroupsPipe,
		FilterGreenPercentagesPipe,
		ContractCustomizeUserGridConfigModalComponent,
		ContractNewUserGridConfigModalComponent,
		ContractEditUserGridConfigModalComponent,
		FilterStateColumnsPipe,
		LookupContractComponent,
		MarketUpdateRateSummaryFilterPipe,
		MarketUpdateRateSummaryServiceTypeFilterPipe,
		MarketUpdateAuctionFilterPipe,
		MarketUpdateAuctionServiceTypeFilterPipe,
		SessionFilterPipe,
		PagerComponent,
		ConvertBidUnitsPipe,
		EmailPreferenceFilterPipe,
		FilterPlanLoadFactorSettingsPipe,
		ActionDropdownComponent,
		AgentSelectorComponent,
		ContractSelectorComponent,
		ContractSelectorModalComponent,
		UserSelectorComponent,
		FileSelectorComponent,
		FilterDropdownComponent,
		FormDropdownComponent,
		TypeaheadComponent,
		FormElementComponent,
		HtmlEditorComponent,
		AgentCommissionPayableSchedulesPipe,
		SupplierNoteComponent,
		CompensationFilterPipe,
		AuditReportFriendlyDateColumnPipe,
		FilterReportCommissionPaidPipe,
		FaqShortAnswerPipe,
		VjsPlayerComponent,
		FilterEducationCategoryTopicsPipe,
		FilterEducationPipe,
		FilterReportCommissionPaidParentPipe,
		ClickStopPropagationDirective,
		DblClickDirective,
		StatesFilterPipe,
		RfqSettingsFilterPipe,
		GeneralContractTableComponent,
		OperationsContractActivityTableComponent,
		RfqSettingsFilterPipe,
		AgentCommissionsPaidReportPipe,
		RateBarComponent,
		LicensePlanNameFilterPipe,
		CustomerAvailabilityComponent,
		CustomerAvailabilityViewComponent,
		AgentCommissionPayablesPipe,
		LicensePlanControlFilterPipe,
		FormatCommissionPayPlanDetailsPipe,
		NewLinesToBreaksPipe,
		FilterPricingAnalyticsEffectiveDatesPipe,
		FilterPricingAnalyticsUtilitiesPipe,
		FilterPricingAnalyticsTermsPipe,
		FilterPricingAnalyticsUsagesPipe,
		EmailPreferenceSettingTargetDateOptionsFilterPipe,
		ModalComponent,
		TopBannerAlertComponent,
		TopBannerAlertLearnMoreModalComponent,
		OptionsFilterDropdownComponent,
		FilterContractLocationStatusReasonTypesPipe,
		RateClassFilterPipe,
		NumberOrdinalPipe,
		ViewAgentEngagementEventsSortPipe,
		FilterEmailCriteriasPipe,
		GenerateLoaLoeModalComponent,
		DblClickDirective,
		MarketRecommendationsComponent,
		SelectImageModalComponent
	],
	exports: [
		ShrinkPipe,
		FilterPipe,
		OrderByPipe,
		FormatLicensePlansPipe,
		ServiceTypeUtilitiesPipe,
		OrderByControlPipe,
		FilterControlPipe,
		WelcomeModalComponent,
		LoaderComponent,
		FormsModule,
		ReactiveFormsModule,
		NgxFilesizeModule,
		DxDataGridModule,
		DxDateBoxModule,
		DxFileUploaderModule,
		DxListModule,
		DxSortableModule,
		DxScrollViewModule,
		DxColorBoxModule,
		PageWarningsComponent,
		MonthNamePipe,
		TelPipe,
		FilterPlansPipe,
		FilterBestPlansPipe,
		SalesTaxPipe,
		LoadFactorPipe,
		ProductBidsPipe,
		SupplierBidsPipe,
		UtilityFilterPipe,
		ZoneFilterPipe,
		RateClassFilterPipe,
		PriorityPipe,
		WarningsFilterPipe,
		LicensePlanFilterPipe,
		LicensePlanNameFilterPipe,
		AddressValidationModalComponent,
		InternalNotesPipe,
		PhonePipe,
		NgxExtendedPdfViewerModule,
		BillAttachmentsPipe,
		NgxJsonViewerModule,
		JoinPipe,
		MinusSignToParensPipe,
		UtilityServiceTypeMapFilterPipe,
		MarketOverviewStateFilterPipe,
		MarketOverviewServiceTypeFilterPipe,
		MarketOverviewUtilityFilterPipe,
		SafeHtmlPipe,
		AgentCommissionsPipe,
		EsiidLookupComponent,
		TakeoverPopUpModalComponent,
		FeatureTourModalComponent,
		AgentCommissionPayableSchedulesPipe,
		AgentCommissionsPaidReportPipe,
		NgJsonEditorModule,
		MarginDisplayPipe,
		AgentsWithLoginsPipe,
		FilterSuppliersPipe,
		FilterTermsPipe,
		FilterLoadersPipe,
		FilterProductsPipe,
		FilterLoaderGroupsPipe,
		FilterGreenPercentagesPipe,
		FontAwesomeModule,
		ModalModule,
		CollapseModule,
		BsDropdownModule,
		BsDatepickerModule,
		CarouselModule,
		TabsModule,
		TooltipModule,
		ButtonsModule,
		ProgressbarModule,
		AccordionModule,
		LookupContractComponent,
		TextMaskModule,
		MarketUpdateRateSummaryFilterPipe,
		MarketUpdateRateSummaryServiceTypeFilterPipe,
		MarketUpdateAuctionFilterPipe,
		MarketUpdateAuctionServiceTypeFilterPipe,
		SessionFilterPipe,
		PagerComponent,
		ConvertBidUnitsPipe,
		EmailPreferenceFilterPipe,
		FilterPlanLoadFactorSettingsPipe,
		TypeaheadModule,
		ActionDropdownComponent,
		AgentSelectorComponent,
		ContractSelectorComponent,
		UserSelectorComponent,
		FileSelectorComponent,
		FilterDropdownComponent,
		OptionsFilterDropdownComponent,
		FormDropdownComponent,
		TypeaheadComponent,
		FormElementComponent,
		HtmlEditorComponent,
		SupplierNoteComponent,
		CompensationFilterPipe,
		AuditReportFriendlyDateColumnPipe,
		FilterReportCommissionPaidPipe,
		TagInputModule,
		AuditReportFriendlyDateColumnPipe,
		FaqShortAnswerPipe,
		VjsPlayerComponent,
		FilterEducationCategoryTopicsPipe,
		FilterEducationPipe,
		FilterReportCommissionPaidParentPipe,
		ClickStopPropagationDirective,
		DblClickDirective,
		StatesFilterPipe,
		GeneralContractTableComponent,
		OperationsContractActivityTableComponent,
		RfqSettingsFilterPipe,
		RateBarComponent,
		CustomerAvailabilityComponent,
		CustomerAvailabilityViewComponent,
		AgentCommissionPayableSchedulesPipe,
		AgentCommissionPayablesPipe,
		LicensePlanControlFilterPipe,
		FormatCommissionPayPlanDetailsPipe,
		NewLinesToBreaksPipe,
		FilterPricingAnalyticsEffectiveDatesPipe,
		FilterPricingAnalyticsUtilitiesPipe,
		FilterPricingAnalyticsTermsPipe,
		FilterPricingAnalyticsUsagesPipe,
		EmailPreferenceSettingTargetDateOptionsFilterPipe,
		ModalComponent,
		TopBannerAlertComponent,
		TopBannerAlertLearnMoreModalComponent,
		FilterContractLocationStatusReasonTypesPipe,
		NumberOrdinalPipe,
		ViewAgentEngagementEventsSortPipe,
		FilterEmailCriteriasPipe,
		GenerateLoaLoeModalComponent,
		MarketRecommendationsComponent,
	],
	providers: [
		WINDOW_PROVIdERS,
		FilterPlansPipe,
		FilterBestPlansPipe,
		CurrencyPipe,
		OrderByPipe,
		DatePipe,
		DecimalPipe,
		OrderByControlPipe,
		MinusSignToParensPipe,
		ZoneFilterPipe,
		RateClassFilterPipe,
		AgentCommissionsPipe,
		AgentCommissionsPaidReportPipe,
		ShrinkPipe,
		StatesFilterPipe,
		UtilityFilterPipe,
		EmailPreferenceFilterPipe,
		CompensationFilterPipe,
		LoadFactorPipe,
		FilterReportCommissionPaidPipe,
		ServiceTypeUtilitiesPipe,
		AgentCommissionPayableSchedulesPipe,
		PhonePipe,
		NumberOrdinalPipe,
		ViewAgentEngagementEventsSortPipe,
	],
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(
			faQuestion,
			faSignOutAlt,
			faExchangeAlt,
			faSearchDollar,
			faSearch,
			faBars,
			faTimes,
			faBookmark,
			faBook,
			faRocket,
			faBell,
			farBell,
			faExclamationTriangle,
			faTriangleExclamation,
			faCog,
			faCogs,
			faSpinner,
			faLaptopMedical,
			faQuestionCircle,
			faPhotoVideo,
			faPencilAlt,
			faChevronRight,
			faChevronDown,
			faChevronLeft,
			faChevronUp,
			faCheck,
			faUserSecret,
			faPlus,
			faEdit,
			faChevronCircleDown,
			faChevronCircleUp,
			faTrash,
			faTrashAlt,
			faCloudDownloadAlt,
			faChartBar,
			faChartTreeMap,
			faEye,
			faEyeSlash,
			faLock,
			faCommentDollar,
			faFileInvoiceDollar,
			faTasks,
			faPaperPlane,
			faCheckCircle,
			faMedal,
			faGem,
			faInfoCircle,
			faEnvelope,
			faPlusCircle,
			faUpload,
			faFileAlt,
			faArrowRightLong,
			faArrowRightArrowLeft,
			faCircle,
			faCircleInfo,
			faCircleExclamation,
			farCircleExclamation,
			faGavel,
			faFile,
			faMapLocationDot,
			faFlag,
			faSquare,
			farSquare,
			faSquarePlus,
			farSquarePlus,
			faSquareCheck,
			faMailBulk,
			faTable,
			faSave,
			faStar,
			faUser,
			faDisplay,
			faDownload,
			faPhone,
			faDollarSign,
			faHome,
			faPencilAlt,
			faMedkit,
			faTachometerAlt,
			faChartLine,
			faStar,
			faExchangeAlt,
			faTimesCircle,
			farCheckSquare,
			faExternalLinkAlt,
			faExternalLinkSquareAlt,
			faShareSquare,
			faShareNodes,
			faNewspaper,
			faNoteSticky,
			faChartPie,
			faAngleRight,
			faComment,
			faPlay,
			faUndo,
			faCalendar,
			faCalendarAlt,
			faCalendarDays,
			faEllipsisH,
			faEllipsisVertical,
			faCaretRight,
			faCaretLeft,
			faCaretSquareRight,
			faFileInvoice,
			faExclamationCircle,
			faArchive,
			faBullhorn,
			farSmile,
			farFrown,
			farLightbulb,
			farTimesCircle,
			faUserTie,
			faCommentsDollar,
			faFileDownload,
			faFileSpreadsheet,
			faPhoneAlt,
			faClock,
			faList,
			faCaretDown,
			faCaretUp,
			faClone,
			faHandHoldingUsd,
			faPhoneVolume,
			farFilePdf,
			faRedoAlt,
			faReply,
			faPercentage,
			faExternalLinkAlt,
			faPlay,
			faPercent,
			faUndoAlt,
			faFileContract,
			faBoxOpen,
			faPlug,
			faMoneyBillWave,
			faPenSquare,
			faFilm,
			faFolderOpen,
			farFolderOpen,
			farFileAlt,
			farMeh,
			farComments,
			farGrinStars,
			faListUl,
			faUserCheck,
			farPlusSquare,
			faComments,
			farCalendarAlt,
			faFileUpload,
			faUserSlash,
			faUsers,
			faSlash,
			faEnvelopeSquare,
			faArrowsAlt,
			faCircleChevronLeft,
			faClipboardList,
			faFileExport,
			faThList,
			farListAlt,
			faFileExcel,
			faPhoneSlash,
			faExclamationCircle,
			farCirclePlay,
			farCircleStop,
			faFileCircleXmark,
			faCircleXmark,
			faCircleMinus,
			faSliders,
			faArrowsUpDownLeftRight,
			faRotateRight,
			faInfo,
			faXmark,
			faAnglesDown,
			faAnglesUp,
			faToggleOn,
			faToggleOff,
			faFlag,
			farNoteSticky,
			faSquareInfo,
			farCircleCheck,
			farBallotCheck,
			fadCircleXmark,
			faMagnifyingGlass,
			faPlugCircleXmark,
			faFileCheck,
			faFloppyDiskCircleArrowRight,
			faArrowUpToLine,
			faArrowDownToLine,
			farSquareXmark,
			faCalendarLinesPen,
			farCalendarLinesPen,
			farNotes,
			faGift,
			faArrowLeftFromLine,
			falMessageQuestion,
			farFileCertificate,
			farBadgeDollar,
			faPiggyBank,
			faHeadset,
			farFileSignature,
			faPartyHorn,
			faUserGroup,
			faArrowsSpin,
			faNotes,
			farChampagneGlasses,
			faClockRotateLeft,
			faChartKanban,
			farRefrigerator,
			faMobileScreen,
			faPhoneOffice,
			farList,
			farClock,
			faEnvelopesBulk,
			faCheckDouble,
			falPipe,
			faFileExclamation,
			faLayerGroup,
			faEmptySet,
			faMessageDollar,
			faChartPieSimpleCircleDollar,
			farChartPieSimpleCircleDollar,
			farCalendarCheck,
			faPlugCircleBolt,
			faThumbsDown,
			farCity,
			farChartLineDown,
			faDesktop,
			faMobileNotch,
			faCloudSun,
			faSunCloud,
			faLinkedin,
			faPenToSquare,
			falFileInvoice,
			farFileCircleQuestion,
			farSensorTriangleExclamation,
			farEnvelopeCircleCheck,
			faArrowUpRightFromSquare,
			farCopy,
			faTrashUndo,
		);
	}
}
